import React from 'react';
import { Link } from 'gatsby';
import Loadable from '@loadable/component';
// Style Imports
// import '../styles.scss';
// Content imports
import Image from '@components/content/fractals-image.jsx';
import Hero from '@components/content/hero/Hero.Homepage.jsx';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
// Utility imports
import Seo from '@components/utility/SEO';
import { getLocalizedURL } from '@src/helper';

// Content imports
const IconMailLarge = Loadable(() => import('@components/content/icons/MailLarge.jsx'));
const Cta = Loadable(() => import('@components/content/cta/CTA'));



const Index = () => (
	<Layout addedClass='page--home'>
		<Seo
			title='Una opción para la nefritis lúpica | LUPKYNIS&reg; (voclosporina)'
			description='LUPKYNIS es un medicamento recetado que se usa con otros medicamentos para tratar a adultos con nefritis lúpica activa. Vea cómo funciona LUPKYNIS e inscríbase para recibir las últimas noticias. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA.'
			canonicalURL={'https://www.lupkynis.com'+getLocalizedURL('home','es')}
		/>
		<Hero />
		<section id='LearnMore' className='bg-light-green'>
			<div className='rounded-banner text-center'>
				<div className='container'>
					<p>
					En el 97 % de los casos*, los pacientes pagaron menos de $10 por su receta de LUPKYNIS.
					</p>
					<p>
					Inscríbase <Link to={getLocalizedURL('registration','es')}>aquí</Link> para comenzar.
					</p>
					<p className='disclaimer'>
					*El 97 % de los envíos de LUPKYNIS entregados le costaron al paciente $10 o menos. Estos son pacientes con seguro comercial, Medicaid o Medicare desde enero de 2021 hasta junio de 2022.
					</p>
				</div>
			</div>
			<div className='container'>
				<div className='text-center copy-block'>
					<h2>
					LUPKYNIS es el{' '}
						<span className='ripped-tape'>
							1<sup>er</sup> tratamiento oral aprobado por la FDA
						</span>{' '}
						<span>específicamente</span> para la nefritis lúpica
					</h2>
				</div>
				<div className='grid-wrapper'>
					<div className='grid'>
						<div className='grid__row'>
							<div className='grid__item'>
								<Image
									data={{
										desktopSrc: 'home/appt-sticky-es.png',
										mobileSrc: 'home/appt-sticky--mobile-es.png',
									}}
								/>
							</div>
							<div className='grid__item'>
								<div className='grid__copy'>
									<p>
									LUPKYNIS funciona ayudando a detener el ataque de nefritis lúpica, mientras protege los riñones del daño irreversible causado por esta enfermedad grave.
									</p>
									<p>
									En ensayos clínicos, LUPKYNIS ayudó a controlar la nefritis lúpica con dosis bajas de esteroides.
									</p>
									<Cta
										url={getLocalizedURL('taking-lupkynis','es')+'#WatchVideo'}
										isCircleArrow={true}>
										Vea cómo LUPKYNIS ayuda a las personas
									</Cta>
								</div>
							</div>
						</div>
						<div className='grid__row'>
							<div className='grid__item'>
								<Image
									data={{
										desktopSrc: 'home/hug-sticky-es.png',
										mobileSrc: 'home/hug-sticky--mobile-es.png',
										altText:
											'Alrededor de 1 de cada 2 personas con lupus puede desarrollar nefritis lúpica',
									}}
								/>
							</div>
							<div className='grid__item'>
								<div className='grid__copy'>
									<h3 className='heading heading--subhead'>
									La nefritis lúpica es un ataque a los riñones causado por el lupus
									</h3>
									<p>
										<strong>
										La nefritis lúpica es una complicación grave y frecuente que puede desarrollarse en 1 de cada 2 personas que presentan lupus.
										</strong>{' '}
										Si no se trata, la nefritis lúpica a menudo provoca problemas de salud graves, daño renal permanente e incluso insuficiencia renal. 
									</p>
									<Cta
										url={getLocalizedURL('what-is-lupus-nephritis','es')}
										isCircleArrow={true}>
										Obtenga más información sobre la nefritis lúpica
									</Cta>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id='StayInformed' className='bg-coral text-center'>
			<div className='container container--narrow'>
				<div className='copy-block'>
					<IconMailLarge />
					<h2>Manténgase informado</h2>
					<p>
					Inscríbase en las últimas noticias de LUPKYNIS y obtenga más información sobre Aurinia Alliance<sup>&reg;</sup>, nuestro programa de apoyo personalizado para personas que toman LUPKYNIS.{' '}
						<strong>
						También puede solicitar que un administrador de casos del personal de enfermería de Aurinia Alliance se comunique con usted para ayudarlo a inscribirse en el programa y vincularlo a otros servicios como nuestro programa de copago para pacientes comerciales elegibles.
						</strong>
					</p>
					<div>
						<Link to={getLocalizedURL('registration','es')} className='button'>
						Inscribirse
						</Link>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default Index;
